/* src/Chatbot.css */
.chatBContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

.chatContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid #ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  box-sizing: border-box;
}

.chat-window {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background: #f7f7f7;
  box-sizing: border-box;
  min-height: 20pxs;
  max-height:  1000px;
}

.message {
  margin: 5px 0;
  padding: 10px;
  border-radius: 5px;
  width: 80%;
}

.message.user {
  align-self: flex-end;
  margin-left: auto;
  background-color: #dcf8c6;
}

.message.assistant {
  align-self: flex-start;
  background-color: #fff;
  border: 1px solid #ddd;
}

.input-container {
  display: flex;
  border-top: 1px solid #ccc;
  padding: 10px;
  background-color: white;
  align-items: center;
  box-sizing: border-box;
}

.input-container input {
  flex: 1;
  padding: 10px;
  border: none;
  box-sizing: border-box;
  width: 60%;
  border: 1px solid gainsboro;
}

.input-container button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  width: 40%; /* Set the width to 30% of the parent */
  margin-left: 10px; /* Optional margin for spacing between input and button */
  box-sizing: border-box;
}

.switch-button-container {
  text-align: center;
  padding: 10px;
}

.btn1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px; /* Set the height of the button */
  padding: 0 10px; /* Adjust padding as needed */
  background-color: #7eb3e8;
  border: none;
  border-radius: 5px;
  color: black;
  font-size: 16px;
  transition: 0.3s;
  margin-left: auto
}

.btn1:hover {
  background-color: #3566ec;
  color: white;
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.icon {
  margin-left: 5px; /* Adjust margin as needed */
}

.btn-text {
  margin-right: 8px; /* Spacing between text and icon */
}

.btn-icon {
  display: flex;
  align-items: center;
}

.btn1:hover {
  background-color: #3566ec;
  color: white;
}
