.root {
}

.root table {
    font-size:small;
    border-collapse: collapse;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 20px;
}

.root td {
    border: 1px solid #ddd;
    padding: 8px 14px;
    text-align: left;
}

.root th {
    border: 1px solid #ddd;
    padding: 8px 14px;
    text-align: left;
}

.root tr:nth-child(even) {
    background-color: #f2f2f2;
}

.root tr:hover {
    background-color: #ddd;
}


.cellCommands {
    width: 400px;
}

.cellsize {
    width: 100px;
}


.statTable {
    overflow: auto;
    max-height: 600px;
    width: 100%; 
}

.dropdown .pro-sidebar {
    width: 100%;
    min-width: 100%;
  }
  .dropdown .pro-sidebar.collapsed {
    width: 80px;
    min-width: 80px;
  }
  .dropdown .pro-sidebar-inner {
    background-color: white;
    box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
  }
  .dropdown .pro-sidebar-inner .pro-sidebar-layout {
    overflow-y: hidden;
  }
  .dropdown .pro-sidebar-inner .pro-sidebar-layout .logotext p {
    font-size: 20px;
    padding: 0 20px;
    color: #000;
    font-weight: bold;
  }
  .dropdown .pro-sidebar-inner .pro-sidebar-layout ul {
    padding: 0 5px;
  }
  .dropdown .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
    color: #000;
    margin: 10px 0px;
    font-weight: bold;
  }
  .dropdown .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
    background-color: #fbf4cd;
    color: #000;
    border-radius: 3px;
  }
  .dropdown .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
    color: #000;
  }
  .dropdown .pro-sidebar-inner .pro-sidebar-layout .active {
    background-image: linear-gradient(0deg, #fece00 0%, #ffe172 100%);
  }