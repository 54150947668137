.root {
    height: 500px;
    width: 500px;
    margin: auto;
    margin-top: 100px;
    /* border: 1px solid grey; */
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
}


